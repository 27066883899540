import React from 'react'
import './index.scss'
import { FiX } from 'react-icons/fi'
const MultiSelect = ({ options, value = [], onChange }) => {
    const [open, setOp] = React.useState(false)
    const filtered = options.filter(a => !value.includes(a[1]))
    return (
        <div className="multi-select" onClick={e => e.stopPropagation() || setOp(a => !a)}>
            <div className="selected">
                <div>
                    {value.map(a => <span key={a} onClick={e => e.stopPropagation() || onChange(value.filter(b => a !== b))}>{a}<FiX /></span>)}
                </div>
                {/* <FiChevronDown /> */}
            </div>
            {!!open && <div className="options">
                {!filtered.length ? <div className="option-item">Pas de catégorie supplémentaire disponible.</div> : filtered.map(a => {
                    return <div className="option-item" key={a[1]} onClick={() => onChange(value.includes(a[1]) ? value.filter(b => a[1] !== b) : [...value, a[1]])}>{a[0]}</div>
                })}
            </div>}
        </div>
    )
}

export default MultiSelect
